import React from 'react';
import Hero from '../components/Hero';
import Row from '../components/Row';
import ImageOverlay from '../components/ImgOverlay';
import '../styles/projects.scss';

class ProjectsPage extends React.Component {
  overlayRef = React.createRef();

  showOverlay(src) {
    this.overlayRef.show(src);
  }

  render() {
    return <div id="portfolio">
      <Hero>
        <h1>Portfolio</h1>
        <h2>Need help with front-end, back-end, or mobile development? Reach out to me.</h2>
      </Hero>

      <Row>
        <div className="flex">
          <div>
            <img src={"/images/projects/tw-web/tw-pdp.jpg"} className="project-img full-width" onClick={e => this.showOverlay(e.target.src)} alt="" />
            <img src={"/images/projects/tw-web/tw-cart.jpg"} className="project-img full-width" onClick={e => this.showOverlay(e.target.src)} alt="" />
          </div>
          <div>
            <h3>TotalWine - Web</h3>
            <p>Redesigned product page to a React micro front-end site.</p>
            <p>New product page saw over <strong>16% increase to add-to-cart</strong>.</p>
            <p>Design and develop back-end APIs using Go to serve as a bridge to the clients’ commerce back-end.</p>
            <p>Make key development design to optimize app efficiency, usability, and speed.</p>
            <p>Facilitate beta releases for testing the app with the BA, QA, and the client.</p>
            <p>Lead developers by training and encouraging their growth.</p>
          </div>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/babel.png"} alt="" /> Babel</li>
          <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
          <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
          <li><img src={"/images/skills/node-js.png"} alt="" /> NodeJS</li>
          <li><img src={"/images/skills/opencomponents.png"} alt="" /> Open Components</li>
          <li><img src={"/images/skills/react-js.png"} alt="" /> React</li>
          <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
          <li><img src={"/images/skills/redux.png"} alt="" /> Redux</li>
          <li><img src={"/images/skills/webpack.png"} alt="" /> Webpack</li>
        </ul>
      </Row>

      <Row>
        <div className="flex">
          <div>
            <img
              src={"/images/projects/tw-app/tw-app-home.png"}
              className="project-img half-width"
              onClick={e => this.showOverlay(e.target.src)}
              alt=""
            />
            <img
              src={"/images/projects/tw-app/tw-app-pdp.png"}
              className="project-img half-width"
              onClick={e => this.showOverlay(e.target.src)}
              alt=""
            />
          </div>
          <div>
            <h3>TotalWine - App</h3>
            <p>Developed a customer facing app for iOS and Android using React Native, Objective-C, Java, and Javascript.</p>
            <p>App was downloaded over <strong>500,000 times</strong>.</p>
            <p>Generated over <strong>$8,000,000 in revenue</strong>.</p>
          </div>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/babel.png"} alt="" /> Babel</li>
          <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
          <li><img src={"/images/skills/java.png"} alt="" /> Java</li>
          <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
          <li><img src={"/images/skills/jest.png"} alt="" /> Jest</li>
          <li><img src={"/images/skills/node-js.png"} alt="" /> NodeJS</li>
          <li><img src={"/images/skills/objective-c.png"} alt="" /> Objective C</li>
          <li><img src={"/images/skills/react-native.png"} alt="" /> React Native</li>
          <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
          <li><img src={"/images/skills/redux.png"} alt="" /> Redux</li>
          <li><img src={"/images/skills/webpack.png"} alt="" /> Webpack</li>
        </ul>
      </Row>

      {/*<Row>
        <h3>OmniMerse</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut delectus dicta dignissimos enim est explicabo facere
          illo, inventore laudantium natus ratione rerum saepe totam velit veritatis. Autem delectus magni obcaecati.</p>
      </Row>*/}

      <Row>
        <div>
          <h3>YouFit</h3>
          <p>Wrote microservices in Go for authentication, membership management, and club details</p>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
          <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
        </ul>
      </Row>

      <Row>
        <div>
          <h3>Mor Furniture</h3>
          <p>Worked on a new store locator.</p>
          <p>Built a new order payment system.</p>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/babel.png"} alt="" /> Babel</li>
          <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
          <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
          <li><img src={"/images/skills/react-js.png"} alt="" /> React</li>
          <li><img src={"/images/skills/redux.png"} alt="" /> Redux</li>
          <li><img src={"/images/skills/webpack.png"} alt="" /> Webpack</li>
        </ul>
      </Row>

      <Row>
        <div>
          <h3>City Furniture</h3>
          <p>Created microservices using NodeJS to get inventory from their system, with caching</p>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
          <li><img src={"/images/skills/node-js.png"} alt="" /> NodeJS</li>
          <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
        </ul>
      </Row>

      <Row>
        <div>
          <h3>Telit</h3>
          <p>Worked on the front-end dashboard (PHP, HTML, CSS) for customers to interact and view reports from their IoT devices.</p>
          <p>Create new endpoints and maintained the back-end APIs. The back-end was a Go codebase with Redis caching and MongoDB database.</p>
        </div>
        <ul className="skills ten-col small-img">
          <li><img src={"/images/skills/bootstrap.png"} alt="" /> Bootstrap</li>
          <li><img src={"/images/skills/cakephp.png"} alt="" /> CakePHP</li>
          <li><img src={"/images/skills/css3.png"} alt="" /> CSS</li>
          <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
          <li><img src={"/images/skills/html5.png"} alt="" /> HTML</li>
          <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
          <li><img src={"/images/skills/jquery.png"} alt="" /> JQuery</li>
          <li><img src={"/images/skills/mongodb.png"} alt="" /> Mongo</li>
          <li><img src={"/images/skills/php.png"} alt="" /> PHP</li>
          <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
        </ul>
      </Row>


      <ImageOverlay ref={ref => this.overlayRef = ref} alt="" />
    </div>
  }
}

export default ProjectsPage;
