import React  from 'react';

class ImageOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      imgSrc: '',
    };

    this.ref = null;
  }

  show(src) {
    if (!src) return;

    this.setState({ show: true, imgSrc: src }, () => {
      setTimeout(() => {
        this.ref.setAttribute('show', true);
      }, 250);
    });
  }

  hide() {
    this.setState({ show: false, imgSrc: '' });
  }

  render() {
    if (!this.state.show) return null;

    return <div className="img-overlay" ref={ref => this.ref = ref}>
      <button className="close" onClick={() => this.hide()}>&times;</button>
      <img src={this.state.imgSrc} alt="" />
    </div>;
  }
}

export default ImageOverlay;
