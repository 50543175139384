import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import SkillsPage from './pages/SkillsPage';
import Footer from './components/Footer';
import NotFoundPage from './pages/NotFoundPage';
import './styles/app.scss';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/projects" Component={ProjectsPage} />
        <Route path="/skills" Component={SkillsPage} />
        <Route path="*" Component={NotFoundPage} />
      </Routes>
      <Footer />

    </Router>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// const ScrollToTop = withRouter((props) => {
//   const { location: { pathname = '' } = {} } = props;
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// });

export default App;
