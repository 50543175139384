import React from 'react';
import Hero from '../components/Hero';

const NotFoundPage = () => <div>
  <Hero>
    <div>
      <h1>404 - Not Found</h1>
      <h2>Need help with front-end, back-end, or mobile development? Reach out to me.</h2>
    </div>
  </Hero>
</div>;

export default NotFoundPage;
