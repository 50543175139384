import React from 'react';
import Hero from '../components/Hero';
import Row from '../components/Row';

const SkillsPage = () => <div>
  <Hero>
    <div>
      <h1>Skills</h1>
      <h2>Need help with front-end, back-end, or mobile development? Reach out to me.</h2>
    </div>
  </Hero>

  <Row>
    <h3>Languages &amp; Markup</h3>
    <ul className="skills">
      <li><img src={"/images/skills/bash.png"} alt="" /> Bash</li>
      <li><img src={"/images/skills/c++.png"} alt="" /> C++</li>
      <li><img src={"/images/skills/css3.png"} alt="" /> CSS</li>
      <li><img src={"/images/skills/golang.png"} alt="" /> Go</li>
      <li><img src={"/images/skills/graphql.png"} alt="" /> GraphQL</li>
      <li><img src={"/images/skills/html5.png"} alt="" /> HTML</li>
      <li><img src={"/images/skills/java.png"} alt="" /> Java</li>
      <li><img src={"/images/skills/javascript.png"} alt="" /> Javascript</li>
      <li><img src={"/images/skills/less.png"} alt="" /> Less CSS</li>
      <li><img src={"/images/skills/objective-c.png"} alt="" /> Objective C</li>
      <li><img src={"/images/skills/php.png"} alt="" /> PHP</li>
      <li><img src={"/images/skills/python.png"} alt="" /> Python</li>
      <li><img src={"/images/skills/ruby.png"} alt="" /> Ruby</li>
      <li><img src={"/images/skills/sass.png"} alt="" /> Sass CSS</li>
      <li><img src={"/images/skills/Swift.png"} alt="" /> Swift</li>
      <li><img src={"/images/skills/ts.png"} alt="" /> Typescript</li>
    </ul>
  </Row>

  <Row>
    <h3>Frameworks</h3>
    <ul className="skills">
      <li><img src={"/images/skills/apollostudio.png"} alt="" /> Apollo Studio</li>
      <li><img src={"/images/skills/bootstrap.png"} alt="" /> Bootstrap</li>
      <li><img src={"/images/skills/cakephp.png"} alt="" /> CakePHP</li>
      <li><img src={"/images/skills/electron.png"} alt="" /> Electron</li>
      <li><img src={"/images/skills/jquery.png"} alt="" /> JQuery</li>
      <li><img src={"/images/skills/laravel.png"} alt="" /> Laravel</li>
      <li><img src={"/images/skills/opencomponents.png"} alt="" /> Open Components</li>
      <li><img src={"/images/skills/react-js.png"} alt="" /> React</li>
      <li><img src={"/images/skills/react-native.png"} alt="" /> React Native</li>
      <li><img src={"/images/skills/redux.png"} alt="" /> Redux</li>
    </ul>
  </Row>

  <Row>
    <h3>Utilities</h3>
    <ul className="skills">
      <li><img src={"/images/skills/babel.png"} alt="" /> Babel</li>
      <li><img src={"/images/skills/jest.png"} alt="" /> Jest</li>
      <li><img src={"/images/skills/node-js.png"} alt="" /> NodeJS</li>
      <li><img src={"/images/skills/npm.png"} alt="" /> NPM</li>
      <li><img src={"/images/skills/webpack.png"} alt="" /> Webpack</li>
      <li><img src={"/images/skills/yarn.png"} alt="" /> Yarn</li>
    </ul>
  </Row>

  <Row>
    <h3>Databases</h3>
    <ul className="skills">
      <li><img src={"/images/skills/cockroachdb.png"} alt="" /> CockroachDB</li>
      <li><img src={"/images/skills/es.png"} alt="" /> Elasticsearch</li>
      <li><img src={"/images/skills/mongodb.png"} alt="" /> Mongo</li>
      <li><img src={"/images/skills/mysql.png"} alt="" /> MySQL</li>
      <li><img src={"/images/skills/oracle.png"} alt="" /> Oracle</li>
      <li><img src={"/images/skills/redis.png"} alt="" /> Redis</li>
    </ul>
  </Row>

  <Row>
    <h3>Wrap Up</h3>

    <p><strong>Top Day-to-Day technologies</strong> React, React Native, TypeScript, Open Components, Micro Front Ends, Microservices, Javascript, Go, Swift, Objective-C, HTML/CSS, Kubernetes, Docker, Jenkins, Amazon Web Services, Google Cloud Platform, Heroku, Digital Ocean, CockroachDB, Redis, Elasticsearch, GraphQL.</p>
    <br />
    <p><strong>Languages</strong> Go (1.4 - 1.23+), Swift (2, 3), Objective-C, Ruby, C++, HTML (4, 5), Javascript (1.6+), CSS (2, 3), SQL, XML, PHP</p>
    <p><strong>Web Technologies</strong> React, Open Components, Responsive Web Design, SEO, Hystrix, Jest, CSS 2/3, AJAX, jQuery, Bootstrap 2 & 3, Wordpress 3.0 - Latest, Wordpress Plugins & Themes, CakePHP, FatFreeFramework, XML, REST, SOAP, JSON</p>
    <p><strong>Mobile Technologies</strong> iOS Development, Android Development, Swift 2 & 3, Objective-C, Java, React Native, PhoneGap/Cordova, Bootstrap, jQuery Mobile</p>
    <p><strong>Third Party Services</strong> BazaarVoice, RichRelevance, BuddyBuild, Twilio, AWS (EC2, SES, S3, Route 53), Google (Maps, Analytics, Docs, Adwords), Mapquest, Jasper (IoT M2M), Verizon (IoT M2M), Yardi, SiteLink, CenterShift, TalonOne, Forter, Fiserv.</p>
    <p><strong>Marketing</strong> Search Engine Optimization, Social Media Management, Social Media Optimization, Local Search Optimization, Pay-Per-Click Marketing, Email</p>
    <p><strong>Database</strong> MongoDB, Redis, Elasticsearch, MySQL, MariaDB, PostgreSQL, CockroachDB</p>
    <p><strong>Environment</strong> Mac OS X, Unix, Linux (Red Hat Linux, Red Hat Enterprise Linux, CentOS, Ubuntu, Debian, Mandrake/Mandriva), Raspberry Pi, Windows NT/XP/Vista, Windows 7, Windows 8/8.1, Windows 10, Windows Server (2003, 2008)</p>
    <p><strong>Servers & Server Configurations</strong> LAMP, Apache, Nginx, Amazon EC2, Amazon S3, Amazon AWS, SSH, SFTP, Redis, DigitalOcean, Docker, Virtualization, Proxmox, DNS, Networking, Online Backups, StatsD, Grafana, Sendmail, Zipkin, Graylog, Jenkins, Memcache</p>
    <p><strong>Security</strong> PCI Compliance, SSH Keys, Firewalls, SSL/TLS Encryption, Service Auditing, SSL Attack Mitigation (e.g. BEAST, POODLE)</p>
    <p><strong>Networking</strong> Internet of Things (IoT), RFID, DNS</p>
    <p><strong>IDE</strong> IntelliJ IDEA, XCode, WebStorm, Goland, VIM</p>
    <p><strong>Version Control</strong> GIT (CLI), GIT (GUI), Mercurial (CLI), Mercurial (GUI)</p>
    <p><strong>Utilities</strong> MS-Office Suite, Mac iWork Suite, LibreOffice</p>
    <p><strong>Imaging Utilities</strong> Adobe Photoshop, Adobe Creative Suite CS6, Adobe After Effects, Adobe Illustrator, Adobe Fireworks, QuickTime</p>
    <p><strong>Project Management Tools</strong> Jira, InVision, BaseCamp, Agile SCRUM, Agile KANBAN</p>

  </Row>
</div>;


export default SkillsPage;
