import React from 'react';
import { Link } from 'react-router-dom';
import me from '../images/kenkelly2.jpg';
import '../styles/home-page.scss';
import Hero from '../components/Hero';

const HomePage = () => <div id="home">
  <Hero centered>
    <img src={me} alt="" />
    <h1><a href="https://kenhkelly.us" className="invert">Ken Kelly</a></h1>
    <h2>
      Principal Software Architect for Total Wine &amp; More. <br /> I am a passionate about delivering fine crafted projects.
    </h2>
  </Hero>

  <div className="container">
    <div className="flex-container">
      <div className="flex-3">
        <h3>About Me</h3>
        <p>I am a Principal Software Architect currently working for Total Wine &amp; More. I deliver beautifully crafted projects with passion.</p>

        <p>In my younger years, I was fascinated with web technology, and as a result I built my first website, a fan-site for a
          video game I played at the time. From that day forward, I was hooked.</p>

        <p>After many years of dedication and hard-work, I worked to perfect my craft, exceptional application design, front-end
          design, and superior back-end development. Coupled with speed and power, my applications are modern, clean, and functional.</p>

        <p>To accomplish good looking products, I have had the opportunity to learn graphic design using Photoshop, Illustrator,
          and
          motion graphics using After Effects.</p>

        <p>My passion is my career path, and it shows in my work. I am proud of everything that I have learned and accomplished. I
          am
          very excited to see where these new technologies will take us.</p>

        <p>Check out my <Link to="/projects">portfolio</Link> to see my work.</p>
      </div>
      <div className="flex-1 contactList">
        <h3>Contact</h3>
        <ul>
          <li><a href="http://www.linkedin.com/in/kenhkelly" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-linkedin-icon" /> LinkedIn</a></li>
          <li><a href="http://github.com/kenhkelly" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-github-icon" /> Github</a></li>
          <li><a href="mailto:hire@kkelly.dev" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-email-icon" /> Email</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>;

export default HomePage;
